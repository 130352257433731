import React from 'react';
import { Skeleton } from '@vernost_dev/vms-react-components/dist/Skeleton';
import { getClassName } from '@/common_js/common';
import * as css from '@/styles/Skeleton.module.css'

function HomePageSkeletonDesktop(props) {
  const { componentToShow = [] } = props
  // console.log("🚀 componentToShow:", componentToShow)

  return (
    <div className={getClassName(css[''])}>
      <BannerSkeleton />
      <SearchWidgetSkeleton />
      <RecentSearchSkeleton />
      <WhyChooseUsSkeleton />
      <OffersSkeleton />
      <ExploreWithIgSkeleton />
      <SignUpBannerSkeleton />
      <FromOurBlogsSkeleton />
      <SeoSectionSkeleton />
      <PoweredBySkeleton />
      <DisclaimerSkeleton />
    </div>
  )
}

const BannerSkeleton = () => {
  return (
    <div className={getClassName(css['d_flex_between'])}>
      <div className={getClassName(css['w_100per'])}>
        <Skeleton
          height={522}
          additionalClassName={[css["react-loading-skeleton"]].join(" ")}
        />
      </div>
      {/* <div>
        <Skeleton
          width={673}
          height={411}
          additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
        />
      </div> */}
    </div>
  )
}

const SearchWidgetSkeleton = () => {
  return (
    <div className={getClassName(css["max_1160_with_mt-40"])}>
      <div className={getClassName(css["SearchWidgetSkeleton_box"])}>
        {[0, 1, 2, 3].map(ele => {
          return (
            <div className={getClassName(css["SearchWidgetSkeleton_box_input"])}>
              <div className={getClassName(css["SearchWidgetSkeleton_box_label"])}>
                <Skeleton
                  width={110}
                  height={10}
                  additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
                />
              </div>
              <Skeleton
                height={20}
                additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
              />
            </div>
          )
        })}
        <div className={getClassName(css["SearchWidgetSkeleton_button"])}>
          <Skeleton
            width={200}
            height={40}
            additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
          />
        </div>
      </div>
    </div>
  )
}

const WhyChooseUsSkeleton = () => {
  return (
    <div className={getClassName(css["max_1160_with_mt60"])}>
      <div className={getClassName(css['d_flex_between'], css["mb47"])}>
        <Skeleton
          width={250}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />
        <Skeleton
          width={80}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />
      </div>
      <div className={getClassName(css['d_flex_between'], css["mb46"], css["gap20"])}>
        {[0, 1, 2, 3].map(ele => {
          return (
            <div className={getClassName(css['w_100per'])}>
              <Skeleton
                height={84}
                additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const OffersSkeleton = () => {
  return (
    <div className={getClassName(css['max_1160_m0'])}>
      <div className={getClassName(css['d_flex_between'], css["mb47"])}>
        <Skeleton
          width={250}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />

        <Skeleton
          width={80}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />
      </div>

      <div className={getClassName(css['d_flex_between'], css["mb46"], css["gap20"])}>
        {[0, 1, 2, 3].map(ele => {
          return (
            <div className={getClassName(css['w_100per'])}>
              <Skeleton
                height={205}
                additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
              />
              <div className={getClassName(css['mb12'])}></div>
              <Skeleton
                width={200}
                height={15}
                additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const ExploreWithIgSkeleton = () => {
  return (
    <div className={getClassName(css['max_1160_m0'])}>
      <div
        className={getClassName(css['d_flex_between'], css["mb48"])}>
        <Skeleton
          width={250}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />

        <Skeleton
          width={80}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />
      </div>

      <div className={getClassName(css['d_flex_between'], css["gap20"])}>
        {[0, 1, 2, 3].map(ele => {
          return (
            <div className={getClassName(css['w_100per'])}>
              <Skeleton
                height={360}
                additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SignUpBannerSkeleton = () => {
  return (
    <div className={getClassName(css['max_1160_m0'])}>
      <div className={getClassName(css['d_flex_between'])}>
        <Skeleton
          width={1160}
          height={109}
          additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
        />

      </div>
    </div>
  )
}


const SeoSectionSkeleton = () => {
  return (
    <div className={getClassName(css['max_1160_mt20'])}>
      <div className={getClassName(css['pt20'])}>
        <Skeleton
          width={180}
          height={19}
          additionalClassName={[css["react-loading-skeleton"]].join(" ")}
        />
      </div>
      <div className={getClassName(css['pt10_w100per'])}>
        <Skeleton
          // width={160}
          height={19}
          additionalClassName={[css["react-loading-skeleton"]].join(" ")}
        />
      </div>
      <div className={getClassName(css['pt10_w100per'])}>
        <Skeleton
          // width={160}
          height={19}
          additionalClassName={[css["react-loading-skeleton"]].join(" ")}
        />
      </div>
      <div className={getClassName(css['pt10_w100per'])}>
        <Skeleton
          // width={160}
          height={19}
          additionalClassName={[css["react-loading-skeleton"]].join(" ")}
        />
      </div>
      <div className={getClassName(css['pt20'])}>
        <Skeleton
          width={130}
          height={19}
          additionalClassName={[css["react-loading-skeleton"]].join(" ")}
        />

        <div className={getClassName(css['pt30'])}>
          <Skeleton
            width={180}
            height={19}
            additionalClassName={[css["react-loading-skeleton"]].join(" ")}
          />
        </div>
        <div className={getClassName(css['pt10_w100per'])}>
          <Skeleton
            // width={160}
            height={19}
            additionalClassName={[css["react-loading-skeleton"]].join(" ")}
          />
        </div>
        <div className={getClassName(css['pt10_w100per'])}>
          <Skeleton
            // width={160}
            height={19}
            additionalClassName={[css["react-loading-skeleton"]].join(" ")}
          />
        </div>

        <div className={getClassName(css['pt30'])}>
          <Skeleton
            width={180}
            height={19}
            additionalClassName={[css["react-loading-skeleton"]].join(" ")}
          />
        </div>
        <div className={getClassName(css['pt10_w100per'])}>
          <Skeleton
            // width={160}
            height={19}
            additionalClassName={[css["react-loading-skeleton"]].join(" ")}
          />
        </div>
        <div className={getClassName(css['pt10_w100per'])}>
          <Skeleton
            // width={160}
            height={19}
            additionalClassName={[css["react-loading-skeleton"]].join(" ")}
          />
        </div>

        <div className={getClassName(css['pt30'])}>
          <Skeleton
            width={180}
            height={19}
            additionalClassName={[css["react-loading-skeleton"]].join(" ")}
          />
        </div>
        <div className={getClassName(css['pt10_w100per'])}>
          <Skeleton
            // width={160}
            height={19}
            additionalClassName={[css["react-loading-skeleton"]].join(" ")}
          />
        </div>

      </div>
    </div>
  )
}


const FromOurBlogsSkeleton = () => {
  return (
    <div className={getClassName(css['max_1160_m0'])}>
      <div className={getClassName(css['d_flex_between'], css["mt65_mb33"])}>
        <Skeleton
          width={250}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />
        <Skeleton
          width={80}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />
      </div>

      <div className={getClassName(css['d_flex_between'], css["mb79"], css["gap20"])}>
        {[0, 1, 2, 3].map(ele => {
          return (
            <div className={getClassName(css['w_100per'])}>
              <Skeleton
                height={211}
                additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
              />
              <div className={getClassName(css['mb12'])}></div>
              <Skeleton
                width={200}
                height={15}
                additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const RecentSearchSkeleton = () => {
  return (
    <div className={getClassName(css['max_1160_with_mt48'])}>
      <div className={getClassName(css['d_flex_between'], css["mb47"])}>
        <Skeleton
          width={250}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />
        <Skeleton
          width={80}
          height={28}
          additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
        />
      </div>
      <div className={getClassName(css['d_flex_between'], css["mb46"], css["gap20"])}>
        {[0, 1, 2, 3].map(ele => {
          return (
            <div className={getClassName(css['w_100per'])}>
              <Skeleton
                height={84}
                additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const PoweredBySkeleton = () => {
  return (
    <div className={getClassName(css['pt20'], css["mb12"], css["max_1160_m0"])}>
      <Skeleton
        width={200}
        height={20}
        additionalClassName={[css['react-loading-skeleton'], ['margib_btm']].join(" ")}
      />
    </div>
  )
}

const DisclaimerSkeleton = () => {
  return (
    <div className={getClassName(css['w_100per'])}>
      <Skeleton
        height={144}
        additionalClassName={[css["react-loading-skeleton"], css["radius"],].join(" ")}
      />
    </div>
  )
}

export { HomePageSkeletonDesktop, BannerSkeleton, SearchWidgetSkeleton, WhyChooseUsSkeleton, OffersSkeleton, ExploreWithIgSkeleton, SignUpBannerSkeleton, FromOurBlogsSkeleton, RecentSearchSkeleton, PoweredBySkeleton, DisclaimerSkeleton, SeoSectionSkeleton }