'use client';

import React, { useState, useEffect, useRef, Fragment } from 'react'
import * as homestyle from "../../styles/Home.module.css";
import Image from 'next/image'
import LazyLoad from 'react-lazyload';
// import { VmsButton } from '@/components/VmsComponent/Button';
import { getClassName, handle_CTA_redirection } from '@/common_js/common';
import { getInitialValues } from '@/common_js/homepage';
import dynamic from 'next/dynamic';
const WhyChooseUs = dynamic(() => import('@/components/HomePage/WhyChooseUs'))
const OffersForyou = dynamic(() => import('@/components/HomePage/OffersForyou'))
const PopularAmongstTraveller = dynamic(() => import('@/components/HomePage/PopularAmongstTraveller'))
const TopMonsoonDest = dynamic(() => import('@/components/HomePage/TopMonsoonDest'))
const ExploreWithIndigo = dynamic(() => import('@/components/HomePage/ExploreWithig'))
const FromOurBlogs = dynamic(() => import('@/components/HomePage/FromOurBlogs'))
const SearchWidgetDesktop = dynamic(() => import('@/components/common/SearchWidget/SearchWidgetDesktopNew'))
const Banner = dynamic(() => import('@/components/HomePage/Banner'))
const NewBanner = dynamic(() => import('@/components/HomePage/BannerNew'))
const Disclaimer = dynamic(() => import('../../components/common/Disclaimer/DisclaimerDesktop'))
const PoweredBy = dynamic(() => import('../../components/common/PoweredBy/PoweredByDesktop'))
const RecentSearch = dynamic(() => import('../../components/HomePage/RecentSearch'))
const PageLayout = dynamic(() => import("../../components/common/PageLayout/Desktop"))
const VmsButton = dynamic(() => import("@/components/VmsComponent/Button").then((module) => module.VmsButton))
const SeoSection = dynamic(() => import('@/components/HomePage/SeoSection'))
const PromotionSection = dynamic(() => import('@/components/HomePage/PromotionSection'))
import Motion from '@/components/FramerMotion/Motion';

// import NewBanner from '@/components/HomePage/BannerNew';
// import SearchWidgetDesktop from '@/components/common/SearchWidget/SearchWidgetDesktopNew';

// import OffersForyou from '@/components/HomePage/OffersForyou';
// import ExploreWithIndigo from '@/components/HomePage/ExploreWithig';
// import FromOurBlogs from '@/components/HomePage/FromOurBlogs';
// import SearchWidgetDesktop from '@/components/common/SearchWidget/SearchWidgetDesktop';
// import Banner from '@/components/HomePage/Banner';
// import RecentSearch from '../../components/HomePage/RecentSearch';
// import PageLayout from "../../components/common/PageLayout/Desktop"
// import HomePageSkeletonDesktop from '@/components/Skeletons/Hompage/SkeletonDesktop';
import { BannerSkeleton, SearchWidgetSkeleton, RecentSearchSkeleton, WhyChooseUsSkeleton, OffersSkeleton, ExploreWithIgSkeleton, SignUpBannerSkeleton, FromOurBlogsSkeleton, PoweredBySkeleton, DisclaimerSkeleton, SeoSectionSkeleton } from '@/components/Skeletons/Hompage/SkeletonDesktop';
import { pageLoad, setUserDetails } from '../../adobe/homePage.adobe';
import SomethingWentWrong from '../../components/common/ErrorPage/SomethingWentWrong';


export default function HomepageDesktop(props) {
  const { iscrawler } = props
  const [homePageCmsData, setHomePageCmsData] = useState(props?.homePageData?.data || {});
  const [isError, setIsError] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [selectedRecentSearch, setSelectedRecentSearch] = useState();

  useEffect(() => {
    setUserDetails(props.userData)
    async function fetchData() {
      let initialData = {
        cmsData: props.homePageData,
        seoData: props.seoData
      }
      if (!Object.keys(initialData.cmsData).length) {
        initialData = await getInitialValues();
      }

      if (!initialData?.cmsData?.status) {
        setIsError(true);
      }
      setHomePageCmsData(initialData.cmsData.data);
      setisLoading(false)
    }
    if (!props?.homePageData?.data)
      fetchData()
    else {
      let errorDetails = {
        id: "",
        type: "",
        url: "",
        source: "",
        status: "",
        displayMessage: "",
        statusCode: "",
        statusMessage: ""
      }
      pageLoad(errorDetails);

      setTimeout(() => { setisLoading(false) }, 100);
    }

  }, [])

  const handleRecentSearchClick = (data) => {
    data.map((item, i) => {
      if (item.isSelected == true) {
        let searchObj = {
          destDetails: {
            title: data[i].dest_name,
            destination_id: data[i].destination_id,
            search_type: data[i].search_type
          },
          checkIn: data[i].checkin,
          checkOut: data[i].checkout,
          roomsObj: data[i].guest_details
        }
        setSelectedRecentSearch(searchObj)
      }
    })
  }


  return (
    <Fragment>
      {isError ?
        <SomethingWentWrong />
        :
        <>
          {/* banner section */}
          {/* <div className={getClassName(homestyle['ig-HomePage-Banner-container'])}>
            {homePageCmsData.banner ? <Banner banner_data={homePageCmsData.banner} /> : null}
          </div> */}

          {/* banner section */}
          {/* New Banner Section */}
          {/* <div className={getClassName('ig-container')}> */}
          {props.roleName !== 'Agent' ? (<div className={getClassName(homestyle['ig-HomePage-Banner-container'])}>
            <NewBanner
              banner_data={homePageCmsData?.newBanner?.data?.[0]}
              isLoading={isLoading}
              LoadingComponent={<div className={getClassName(['skeleton_margin_0'])}><BannerSkeleton /></div>}
              // LoadingComponent={<div className={getClassName(['skeleton_margin'])}><HomePageSkeletonDesktop componentToShow={["banner"]} /></div>}
              homestyle={homestyle}
              homepage_carousalbanner={homePageCmsData?.homeDesktopBanner?.data}
              homePageCmsData={homePageCmsData.homeDesktopBanner}
            />
          </div>
          ) : (
            <div className={getClassName(homestyle['ig-HomePage-Banner-container'])}>
              <NewBanner
                banner_data={homePageCmsData?.newBanner?.data?.[0]}
                isLoading={isLoading}
                LoadingComponent={<div className={getClassName(['skeleton_margin_0'])}><BannerSkeleton /></div>}
                // LoadingComponent={<div className={getClassName(['skeleton_margin'])}><HomePageSkeletonDesktop componentToShow={["banner"]} /></div>}
                homestyle={homestyle}
                homepage_carousalbanner={homePageCmsData?.AgentHomepageBanner?.data}
                homePageCmsData={homePageCmsData.AgentHomepageBanner}
              />
            </div>
          )

          }



          {/* Search Widget */}
          <div className={getClassName('ig-search-widget-main-container', 'ig-container')}>
            <SearchWidgetDesktop
              selectedRecentSearch={selectedRecentSearch}
              isLoading={isLoading}
              LoadingComponent={<SearchWidgetSkeleton />}
              // LoadingComponent={<HomePageSkeletonDesktop componentToShow={["searchWidget"]} />}
              userData={props.userData}
              popularDestination={homePageCmsData?.popularDestination?.data || []}
            />
          </div>
          {/* Search Widget */}

          {/* Recent Search */}
          <div className={getClassName('ig-container')}>
            <div className={getClassName(homestyle['recent-search-inner-container'])}>
              <RecentSearch
                isMobile={false}
                handleRecentSearchClick={handleRecentSearchClick}
                homestyle={homestyle}
                isLoading={isLoading}
                LoadingComponent={<RecentSearchSkeleton />}
              // LoadingComponent={<HomePageSkeletonDesktop componentToShow={["recent"]} />}
              />
            </div>
          </div>
          {/* Recent Search */}

          <div className={getClassName('ig-container')}>
            {/* Why Choose Us */}

            {props.roleName !== 'Agent' && homePageCmsData.whyChooseUs &&
              <div className={getClassName(homestyle['why-choose-us-container'])}>
                <WhyChooseUs homestyle={homestyle} homePageCmsData={homePageCmsData.whyChooseUs} isLoading={isLoading} LoadingComponent={<WhyChooseUsSkeleton />} />
              </div>
            }

            <PopularAmongstTraveller homestyle={homestyle} homePageCmsData={homePageCmsData.PopularAmongsTravellers} isLoading={isLoading} LoadingComponent={<OffersSkeleton />} iscrawler={iscrawler} />

          </div>
          {/* popular amongst traveller */}

          {/* Top Monsoon Destination */}
          <div className={getClassName('ig-container')}>
            {!iscrawler ?
              <LazyLoad height={660}>
                {/*<Motion>*/}
                <TopMonsoonDest homestyle={homestyle} homePageCmsData={homePageCmsData.popularLocations} isLoading={isLoading} LoadingComponent={<OffersSkeleton />} iscrawler={iscrawler} />
                {/*<Motion>*/}
              </LazyLoad>
              :
              <TopMonsoonDest homestyle={homestyle} homePageCmsData={homePageCmsData.popularLocations} isLoading={isLoading} LoadingComponent={<OffersSkeleton />} iscrawler={iscrawler} />

            }
          </div>

          {/* Explore With Indigo */}
          {homePageCmsData?.exploreWithIg &&
            <div className={getClassName(homestyle['explore-for-you-indigo'])}>
              <div className={getClassName('ig-container')}>
                {!iscrawler ?
                  <LazyLoad height={500}>
                    {/*<Motion>*/}
                    <ExploreWithIndigo isMobile={false} homestyle={homestyle} homePageCmsData={homePageCmsData.exploreWithIg} isLoading={isLoading} LoadingComponent={<ExploreWithIgSkeleton />} iscrawler={iscrawler} />
                    {/*<Motion>*/}
                  </LazyLoad>
                  :
                  <ExploreWithIndigo isMobile={false} homestyle={homestyle} homePageCmsData={homePageCmsData.exploreWithIg} isLoading={isLoading} LoadingComponent={<ExploreWithIgSkeleton />} iscrawler={iscrawler} />
                }
              </div>
            </div>
          }

          {/* Explore With Indigo */}

          {/* promotion */}
          {!props.login ?
            !iscrawler ?
              <LazyLoad height={330}>
                {/*<Motion>*/}
                <PromotionSection homestyle={homestyle} homePageCmsData={homePageCmsData.promotionalSection} isLoading={isLoading} LoadingComponent={<SignUpBannerSkeleton />} login={props.login} iscrawler={iscrawler} />
                {/*<Motion>*/}
              </LazyLoad>
              :
              <PromotionSection homestyle={homestyle} homePageCmsData={homePageCmsData.promotionalSection} isLoading={isLoading} LoadingComponent={<SignUpBannerSkeleton />} login={props.login} iscrawler={iscrawler} />
            : null
          }


          <div className={getClassName('ig-container')}>

            {/* From Our Blogs */}
            <div className={getClassName(homestyle['from-our-blogs-container'])}>
              {!iscrawler ?
                <LazyLoad height={330}>
                  {/*<Motion>*/}
                  <FromOurBlogs homestyle={homestyle} homePageCmsData={homePageCmsData.fromOurBlogs} isLoading={isLoading} LoadingComponent={<FromOurBlogsSkeleton />} iscrawler={iscrawler} />
                  {/*<Motion>*/}
                </LazyLoad>
                :
                <FromOurBlogs homestyle={homestyle} homePageCmsData={homePageCmsData.fromOurBlogs} isLoading={isLoading} LoadingComponent={<FromOurBlogsSkeleton />} iscrawler={iscrawler} />
              }
            </div>
            {/* From Our Blogs */}
          </div>
          {/* seo */}
          <div className={getClassName(homestyle['seo-container'])} >

            {!iscrawler ?
              <LazyLoad height={500}>
                {/*<Motion>*/}
                <SeoSection
                  homestyle={homestyle}
                  homePageCmsData={props?.seoData}
                  isLoading={isLoading}
                  LoadingComponent={<SeoSectionSkeleton />}
                  iscrawler={iscrawler}
                />
                {/*<Motion>*/}
              </LazyLoad>
              :
              <SeoSection
                homestyle={homestyle}
                homePageCmsData={props?.seoData}
                isLoading={isLoading}
                LoadingComponent={<SeoSectionSkeleton />}
                iscrawler={iscrawler}
              />}
          </div>
          {/*seo */}

          <div className={getClassName('ig_powered_disclaimer_main_container')}>
            {/* Powered By */}
            {!iscrawler ?
              <LazyLoad height={1186}>
                <PoweredBy homePageCmsData={homePageCmsData?.poweredText} parentCss={homestyle} isLoading={isLoading} LoadingComponent={<PoweredBySkeleton />} />
              </LazyLoad>
              :
              <PoweredBy homePageCmsData={homePageCmsData?.poweredText} parentCss={homestyle} isLoading={isLoading} LoadingComponent={<PoweredBySkeleton />} />
            }
            {/* Powered By */}

            {/* Disclaimer */}
            {!iscrawler ?
              <LazyLoad height={1186}>
                <Disclaimer homePageCmsData={homePageCmsData?.disclaimerText} isLoading={isLoading} LoadingComponent={<DisclaimerSkeleton />} iscrawler={iscrawler} />
              </LazyLoad>
              :
              <Disclaimer homePageCmsData={homePageCmsData?.disclaimerText} isLoading={isLoading} LoadingComponent={<DisclaimerSkeleton />} iscrawler={iscrawler} />
            }
            {/* Disclaimer */}
          </div>
        </>
      }
    </Fragment>
  )
}

